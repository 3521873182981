import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import Request from './Request';

const allMeasuresByShortName = measures => measures.reduce((seed, val) => {
  if (val.measuretypeshortname && seed[val.measuretypeshortname]) {
    const shortName = val.measuretypeshortname;
    seed[shortName].push(val);
  } else {
    const shortName = val.measuretypeshortname;
    seed[shortName] = [val];
  }

  return seed;
}, {});

const allMeasuresByTypesandShortName = measures => measures.reduce((seed, val) => {
  const categoryName = val.measurecategoryshortname;
  const typeName = val.measuretypeshortname;

  if (categoryName && seed[categoryName]) {
    const measuresCategory = seed[categoryName];

    if (measuresCategory && measuresCategory[typeName]) {
      measuresCategory[typeName].push(val);
    } else {
      measuresCategory[typeName] = [val];
    }
  } else {
    const measuresCategory = {};
    measuresCategory[typeName] = [val];
    seed[categoryName] = measuresCategory;
  }

  return seed;
}, {});

const allMeasuresByType = measures => measures.reduce((seed, val) => {
  if (val.measurecategoryshortname && seed[val.measurecategoryshortname]) {
    const type = val.measurecategoryshortname;
    seed[type].push(val);
  } else {
    const type = val.measurecategoryshortname;
    seed[type] = [val];
  }

  return seed;
}, {});

const measuresByShortName = measures => measures.reduce((seed, val) => {
  if (val.measuretypeshortname) {
    const shortName = val.measuretypeshortname;
    seed[shortName] = val;
  }

  return seed;
}, {});

export class Observation extends Request {
  constructor({
    agency: _agency,
    url,
    keycloak,
    signal
  }) {
    super({
      url,
      signal
    });

    _defineProperty(this, "keycloak", void 0);

    _defineProperty(this, "commonHeaders", {
      Authorization: '',
      'Content-Type': 'application/json',
      Accept: 'application/json'
    });

    _defineProperty(this, "saveManifest", async (submissionForm) => {
      const response = await this.post({
        api: `/observation/patientmanifest`,
        data: submissionForm,
        method: 'POST'
      });
      return response;
    });

    _defineProperty(this, "getSubmissionForms", async (patientNumber, createfromdate, createtodate, formname, modfromdate, modtodate, qtype, personid, agency) => {
      const response = await this.get({
        api: `/observation/formsubmissions`,
        qs: {
          patientNumber,
          createfromdate,
          createtodate,
          formname,
          modfromdate,
          modtodate,
          qtype,
          personid,
          agency
        }
      });
      return response;
    });

    _defineProperty(this, "getMeasureTypes", async (category, patientnumber) => {
      const response = await this.get({
        api: `/observation/measuretypes`,
        qs: {
          category,
          patientnumber
        }
      });
      return response;
    });

    _defineProperty(this, "getAllMeasureTypes", async (patientnumber) => {
      const response = await this.get({
        api: `/observation/measuretypes`,
        qs: {
          patientnumber
        }
      });

      if (Array.isArray(response)) {
        return allMeasuresByType(response);
      }

      return response;
    });

    _defineProperty(this, "getPatientMeasures", async (patientNumber, category, fromdate, todate, type) => {
      const response = await this.get({
        api: `/observation/patientmeasures/patient/${patientNumber}`,
        qs: {
          fromdate,
          todate,
          category,
          type
        }
      });

      if (Array.isArray(response)) {
        return allMeasuresByShortName(response);
      }

      return response;
    });

    _defineProperty(this, "getPrintPatientMeasures", async (patientNumber, fromdate, todate, category, type) => {
      const response = await this.get({
        api: `/observation/patientmeasures/patient/${patientNumber}`,
        qs: {
          fromdate,
          todate,
          category,
          type
        }
      });

      if (Array.isArray(response)) {
        return allMeasuresByTypesandShortName(response);
      }

      return response;
    });

    _defineProperty(this, "getPatientMeasureLatest", async (patientNumber, type) => {
      const response = await this.get({
        api: `/observation/patientmeasurelatest/patient/${patientNumber}/${type}`
      });

      if (Array.isArray(response)) {
        return measuresByShortName(response);
      }

      return response;
    });

    _defineProperty(this, "getPatientMeasuresLatest", async (patientNumber) => {
      const response = await this.get({
        api: `/observation/patientmeasureslatest/patient/${patientNumber}`
      });

      if (Array.isArray(response)) {
        return measuresByShortName(response);
      }

      return response;
    });

    _defineProperty(this, "savePatientMeasure", async (patientMeasure, method) => {
      const response = await this.post({
        api: `/observation/patientmeasure`,
        data: patientMeasure,
        method
      });
      return response;
    });

    _defineProperty(this, "getProgressNotes", async (patientNumber, agency) => {
      const response = await this.get({
        api: `/observation/patientprogressnotes/patient/${patientNumber}`,
        qs: {
          agency
        }
      });
      return response;
    });

    _defineProperty(this, "getPrintProgressNotes", async (patientnumber, agency, fromdate, todate) => {
      const response = await this.get({
        api: `/observation/patientprogressnotes/patientdaterange`,
        qs: {
          patientnumber,
          agency,
          fromdate,
          todate
        }
      });
      return response;
    });

    _defineProperty(this, "saveProgressNote", async (progressNote, agency) => {
      const response = await this.post({
        api: `/observation/patientprogressnote`,
        data: JSON.stringify(progressNote),
        method: 'POST',
        qs: {
          agency
        }
      });
      return response;
    });

    this.url = new URL(url);
    this.keycloak = keycloak;

    if (_agency) {
      this.url.searchParams.append('agency', _agency);
    }

    Object.defineProperty(this.commonHeaders, 'Authorization', {
      get() {
        const {
          token
        } = keycloak;
        return `Bearer ${token}`;
      }

    });
  }

}
export default Observation;
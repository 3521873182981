/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useContext, useEffect } from 'react';
import _ from 'lodash-es';
import { useTheme } from '@material-ui/core/styles';
import { Responsive, WidthProvider } from 'react-grid-layout';
import type { Layout, Layouts } from 'react-grid-layout';
import { useLocation } from 'react-router-dom';
import ClearScripts from '@mummssoftware/clearscripts';
import CarePlanFrequency from '@mummssoftware/care-plan-frequency';
import ScheduledVisits from '@mummssoftware/scheduled-visits';
import CertApp from '@mummssoftware/cert-app';
import Manifest from '@mummssoftware/manifest';
import HospiceItemSet from '@mummssoftware/hospice-item-set';
import ProgressNotes from '@mummssoftware/progressnotes';
import Facesheet from '@mummssoftware/facesheet';
import Forms from '@mummssoftware/forms';
import Measures from '@mummssoftware/measures';
import { Pim } from '@mummssoftware/utils';
import { Watermark, WidgetHeader, THEME } from '@mummssoftware/common-ui';
import {
  WidgetConfig,
  ComponentMap,
  MapProps,
  GetCompProps,
  Size,
} from '../Context/types';
import {
  ScrollWrap,
  BodyWrap,
  WidgetModal,
  ModalDiv,
  Wrap,
} from '../../components/layout/WrapperDiv';
import { DashboardContext } from '../Context/DashboardContext';
import {
  SET_WIDGET_OPEN,
  RESET_WIDGET_OPEN,
  RESET_WIDGET_NAMES_OPEN,
} from '../Context/constants';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const ResponsiveGridLayout = WidthProvider(Responsive);

type DashboardProps = {
  config: WidgetConfig[];
  currentUser: mumms.User;
  keycloak: Keycloak.KeycloakInstance;
  patient?: mumms.patient;
  pim: Pim;
  topBarHeight: number;
  logout?: () => void;
};

export const DashboardGridComponent = ({
  config,
  pim,
  keycloak,
  currentUser,
  patient,
  topBarHeight,
  logout,
}: DashboardProps) => {
  const { search } = useLocation();
  const useQuery = () => new URLSearchParams(search);
  const query = useQuery();
  const agency = query.get('agency');

  const {
    props: {
      dashboard: { widget1by1Height },
    },
  } = useTheme();

  // TODO listen to dispatch with a useEffect useContext
  const theme = localStorage.getItem(THEME);

  const patientNumber = patient && patient.patientNumber;

  const gridConfig = _.cloneDeep(config);

  const [widgetsConfig, setWidgetConfig] = useState<any>(gridConfig);

  const {
    widgetsOpen,
    widgetNamesOpen,
    refreshDashboard,
    dispatch,
  } = useContext(DashboardContext);
  const isWidgetOpen = !!widgetsOpen.length;

  const [key, setKey] = useState<string>('widgetKey');
  useEffect(() => {
    if (refreshDashboard > 0) {
      setKey(`refreshWidgetKey${refreshDashboard}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDashboard]);

  useEffect(() => {
    // if closing the window then refresh the screen with the changes that may have affected the opened widget
    // strategy is change the key of the opened widget, upon closing reset the key and refresh the screen
    if (!isWidgetOpen && widgetNamesOpen.length) {
      const newConfig = _.cloneDeep(widgetsConfig.slice());
      setWidgetConfig(newConfig);
      // clear the open widget names
      setTimeout(
        () =>
          dispatch({
            type: RESET_WIDGET_NAMES_OPEN,
          }),
        1000,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWidgetOpen]);

  // height 320 and width 496 for 1*1 widgets
  const gridRowHeight = widget1by1Height / 2;
  const gridRowWidth = 496;

  const columns = {
    xl: 8,
    lg: 6,
    md: 4,
    sm: 4,
    xs: 2,
    xxs: 2,
  };

  const breakpoints = {
    xl: 1700,
    lg: 1200,
    md: 996,
    sm: 768,
    xs: 480,
    xxs: 0,
  };

  const openWidget = (widget: WidgetConfig) => {
    if (!isWidgetOpen) {
      dispatch({
        type: SET_WIDGET_OPEN,
        payload: widget,
      });
    } else {
      dispatch({
        type: RESET_WIDGET_OPEN,
        payload: widget,
      });
    }
  };

  const getComponent = ({
    name,
    order,
    title,
    height,
    width,
    w,
    h,
    beta,
    watermark,
    onClick,
    smallWidget,
  }: GetCompProps) =>
    Map[name] &&
    Map[name]({
      order,
      name,
      title,
      height,
      width,
      w,
      h,
      beta,
      watermark,
      onClick,
      smallWidget,
    });

  const Map: ComponentMap = {
    DocLink: ({
      name,
      title,
      height,
      watermark,
      onClick,
      smallWidget,
    }: MapProps) => {
      const widgetHeaderHeight = 35;
      const fullSizeRatio = 0.95;
      const calcHeight = smallWidget
        ? height
        : fullSizeRatio * window.innerHeight - widgetHeaderHeight;
      const certKey =
        isWidgetOpen && widgetNamesOpen.includes(name as mumms.ComponentName)
          ? 'DocLink'
          : `DocLinkStatic${refreshDashboard}`;

      // TODO REFACTOR CERT_APP
      return (
        <Wrap height={calcHeight} id="wrap">
          <WidgetHeader
            appTitle={title || 'Certifications'}
            versionInfo={`DocLink v${process.env.REACT_APP_CERT_APP_VERSION}, SmartChart v${process.env.REACT_APP_VERSION}`}
            onClick={onClick}
            smallWidget={smallWidget}
          />
          <BodyWrap smallWidget={smallWidget} id="body-wrap">
            {watermark && watermark.show ? (
              <Watermark
                readOnly={watermark.readOnly}
                smallWidget={smallWidget}
              />
            ) : null}

            <ScrollWrap height={calcHeight as number} id="scroll-wrap">
              <CertApp
                embedded
                smallWidget={smallWidget}
                agency={agency || undefined}
                dateFrom={undefined}
                dateTo={undefined}
                keycloak={keycloak}
                logout={logout}
                patientNumber={patientNumber}
                viewMode="small"
                watermark={watermark}
                theme={theme as string}
                key={certKey}
              />
            </ScrollWrap>
          </BodyWrap>
        </Wrap>
      );
    },
    CarePlanFrequency: ({ title, watermark, onClick }: MapProps) => (
      <CarePlanFrequency
        embedded
        online
        appTitle={title}
        agency={agency || undefined}
        currentUser={currentUser}
        keycloak={keycloak}
        onClick={onClick}
        patient={patient}
        patientNumber={patientNumber}
        pim={pim}
        smallWidget
        view={undefined}
        watermark={watermark}
        theme={theme as string}
        key={key}
      />
    ),
    ScheduledVisits: ({ title, watermark, onClick }: MapProps) => (
      <ScheduledVisits
        embedded
        online
        smallWidget
        appTitle={title}
        currentUser={currentUser}
        keycloak={keycloak}
        patient={patient}
        patientNumber={patientNumber}
        pim={pim}
        agency={agency}
        view={undefined}
        onClick={onClick}
        watermark={watermark}
        theme={theme as string}
        key={key}
      />
    ),
    ClearScripts: ({
      name,
      title,
      watermark,
      onClick,
      smallWidget,
    }: MapProps) => {
      const medsKey =
        isWidgetOpen && widgetNamesOpen.includes(name as mumms.ComponentName)
          ? 'ClearScripts'
          : `ClearScriptsStatic${refreshDashboard}`;
      return (
        <ClearScripts
          dashboardEmbedded
          embedded
          online
          hideActionBar
          smallWidget={smallWidget}
          readonly={smallWidget}
          appTitle={title}
          agency={agency || undefined}
          currentUser={currentUser}
          keycloak={keycloak}
          logout={logout}
          onClick={onClick}
          patient={patient}
          patientNumber={patientNumber}
          viewMode="small"
          watermark={watermark}
          theme={theme as string}
          key={medsKey}
        />
      );
    },
    Facesheet: ({ title, watermark, onClick, smallWidget }: MapProps) => (
      <Facesheet
        online
        smallWidget={smallWidget}
        appTitle={title as string}
        agency={agency}
        keycloak={keycloak}
        onClick={onClick}
        patientNumber={patientNumber}
        watermark={watermark}
        theme={theme as string}
      />
    ),
    Forms: ({ title, watermark, onClick, smallWidget }: MapProps) => (
      <Forms
        dashboardEmbedded
        embedded
        smallWidget={smallWidget}
        appTitle={title as string}
        agency={agency}
        currentUser={currentUser}
        keycloak={keycloak}
        onClick={onClick}
        patientNumber={patientNumber}
        patient={patient}
        pim={pim}
        watermark={watermark}
        theme={theme as string}
        key={key}
      />
    ),
    Manifest: ({ title, watermark, onClick, smallWidget }: MapProps) => (
      <Manifest
        embedded
        smallWidget={smallWidget}
        appTitle={title as string}
        agency={agency}
        keycloak={keycloak}
        onClick={onClick}
        patientNumber={patientNumber}
        currentUser={currentUser}
        patient={patient}
        watermark={watermark}
        theme={theme as string}
        key={key}
      />
    ),
    Measures: ({ name, title, watermark, onClick, smallWidget }: MapProps) => {
      // NOTE key is a hack because of https://github.com/highcharts/highcharts/issues/6427

      const measuresKey =
        isWidgetOpen && widgetNamesOpen.includes(name as mumms.ComponentName)
          ? `Measures${widgetNamesOpen.length}`
          : `MeasuresStatic${refreshDashboard}`;
      return (
        <Measures
          embedded
          smallWidget={smallWidget}
          appTitle={title}
          agency={agency}
          currentUser={currentUser}
          keycloak={keycloak}
          onClick={onClick}
          patient={patient}
          patientNumber={patientNumber}
          pim={pim}
          watermark={watermark}
          theme={theme as string}
          key={measuresKey}
        />
      );
    },
    HospiceItemSet: ({
      name,
      title,
      watermark,
      onClick,
      smallWidget,
    }: MapProps) => 
       (
        <HospiceItemSet
          embedded
          online
          smallWidget={smallWidget}
          appTitle={title}
          agency={agency}
          currentUser={currentUser}
          keycloak={keycloak}
          patient={patient}
          patientNumber={patientNumber}
          pim={pim}
          watermark={watermark}
          theme={theme as string}
          key={key}
          onClick={onClick}
        />
      )
    ,
    ProgressNotes: ({
      name,
      title,
      watermark,
      onClick,
      smallWidget,
    }: MapProps) => {
      const online = navigator.onLine;
      const noteKey =
        isWidgetOpen && widgetNamesOpen.includes(name as mumms.ComponentName)
          ? 'ProgressNotes'
          : `ProgressNotesStatic${refreshDashboard}`;
      return (
        <ProgressNotes
          online={online}
          agency={agency || undefined}
          currentUser={currentUser}
          appTitle={title}
          embedded
          keycloak={keycloak}
          patient={patient}
          patientNumber={patientNumber}
          watermark={watermark}
          smallWidget={smallWidget}
          onClick={onClick}
          theme={theme as string}
          key={noteKey}
        />
      );
    },
  };

  const generateGrid = (widgetconfig: WidgetConfig[]) =>
    widgetconfig.map((widget: WidgetConfig) => (
      <div key={widget.widgetMenuOrder}>
        {getComponent({
          order: widget.widgetMenuOrder,
          name: widget.componentName,
          title: widget.widgetTitle,
          height: widget.widgetHeight,
          width: widget.widgetWidth,
          w: widget.w,
          h: widget.h,
          beta: widget.beta,
          watermark: widget.watermark,
          onClick: () => openWidget(widget),
          smallWidget: true,
        })}
      </div>
    ));

  const getWidgetOpen = (widgets: WidgetConfig[]) => {
    if (widgets.length) {
      return widgets.map((widget: WidgetConfig) => (
        <WidgetModal
          key={widget.widgetMenuOrder}
          numberOpen={widgets.length}
          topBarHeight={topBarHeight}
          name={widget.componentName}
          className="widget-modal"
        >
          {getComponent({
            order: widget.widgetMenuOrder,
            name: widget.componentName,
            title: widget.widgetTitle,
            height: widget.widgetHeight,
            width: widget.widgetWidth,
            w: widget.w,
            h: widget.h,
            beta: widget.beta,
            watermark: widget.watermark,
            onClick: () => openWidget(widget),
            smallWidget: false,
          })}
        </WidgetModal>
      ));
    }
    return null;
  };

  const generateLayoutSize = (size: Size) => {
    const layout: Layout[] = widgetsConfig.map((widget: WidgetConfig) => {
      const { widgetMenuOrder } = widget;
      const i = widgetMenuOrder;
      const { w } = widget;
      const y = widget.h;

      /* eslint-disable indent */
      const column = columns[size];
      return {
        x: (Number(i) * 2) % column,
        y: Math.floor(Number(i) / (column / 2)) * 2,
        w,
        h: y,
        i,
      };
    });
    return layout;
  };

  const generateLayouts = (): Layouts => {
    const layouts: {
      lg: Layout[];
      xl: Layout[];
      md: Layout[];
      sm: Layout[];
    } = {
      lg: [],
      xl: [],
      md: [],
      sm: [],
    };
    layouts.lg = generateLayoutSize('lg');
    layouts.xl = generateLayoutSize('xl');
    layouts.md = generateLayoutSize('md');
    layouts.sm = generateLayoutSize('sm');
    // console.log('layouts', layouts);
    return layouts;
  };

  const onLayoutChange = (layout: any, layouts: any) => {
    // TODO gridConfig the the component name, see how to integrate both
    // localStorage.setItem(WIDGET_CONFIG, JSON.stringify(layout));
    // setStoredWidgetsConfig(layout);
    // NOTE: see if easier rendering
  };

  const onBreakpointChange = (breakpoint: any) => {
    // console.log('breakpoint', breakpoint);
    // NOTE: see diff with fixed width
  };

  // TODO Note: there are 2 compactType: see algorithm, "vertical" | "horizontal"
  return (
    <React.Fragment>
      <ResponsiveGridLayout
        className="layout"
        rowHeight={gridRowHeight}
        layouts={generateLayouts()}
        onLayoutChange={onLayoutChange}
        onBreakpointChange={onBreakpointChange}
        isResizable={false}
        isDraggable={false}
        breakpoints={breakpoints}
        cols={columns}
        margin={[5, 5]}
        compactType="horizontal"
        containerPadding={[5, 5]}
      >
        {generateGrid(widgetsConfig)}
      </ResponsiveGridLayout>
      {/* show as long as any widget is open */}
      <ModalDiv className="modal-div" show={isWidgetOpen}>
        {getWidgetOpen(widgetsOpen)}
      </ModalDiv>
    </React.Fragment>
  );
};
export default DashboardGridComponent;

import React, { Suspense, lazy } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Pim } from '@mummssoftware/utils';
import { DelayedLoader, defaultTheme, THEME } from '@mummssoftware/common-ui';
import { DEFAULT_LOCALE } from './i18n';
import Providers from './Providers';
import { Context, ContextProvider } from './context';
import App from './containers/App';
import { Config, DashboardType } from './containers/Context/types';
import {
  patientDashboard,
  clinicalDashboard,
  FORM_NAME,
} from './containers/Context/constants';
import defaults from './widgetconfig.json';

const texts: any = defaults;

type DashboardProps = {
  keycloak: Keycloak.KeycloakInstance;
  patientNumber?: string;
  theme?: string | null;
  agency?: string | null;
  locale?: keyof mumms.translations;
  /*  controls whether certain elements (i.e. the top bar) display */
  embedded?: boolean;
  /* optional instance of pim, otherwise instantiates its own */
  pim?: Pim;
  /* pass currentUser if already retrieved to avoid excessive api calls- AS OF NOW WOULD NOT HAPPEN */
  currentUser?: mumms.User;
  /* pass patient if already retrieved to avoid excessive api calls- AS OF NOW WOULD NOT HAPPEN */
  patient?: mumms.patient;
  // widget config
  config: Config;
};

const DashboardContext = lazy(() =>
  import('./containers/Context/DashboardContext'),
);
interface DashboardState {
  context: Context | null;
  theme?: string | null;
}

const setPatientError = (res: Response, patientNumber?: string) => ({
  patientError: {
    status: res.status,
    statusText: res.statusText,
    patientNumber,
  },
});

const setPatient = (patient: mumms.patient | undefined) => {
  if (!patient) {
    return undefined;
  }
  return patient;
};

const Content = React.memo(
  ({ context, embedded }: DashboardState & { embedded?: boolean }) => {
    if (context === null) {
      return <DelayedLoader />;
    }
    return (
      <ContextProvider value={context}>
        <Suspense fallback={<DelayedLoader />}>
          <DashboardContext>
            <App
              currentUser={context.currentUser}
              embedded={embedded}
              patient={context.patient}
              patientError={context.patientError}
              keycloak={context.keycloak}
              logout={context.keycloak.logout}
              pim={context.pim}
              config={context.config}
              dashboardType={context.dashboardType}
            />
          </DashboardContext>
        </Suspense>
      </ContextProvider>
    );
  },
);

Content.displayName = 'MainContent';

class Dashboard extends React.PureComponent<DashboardProps, DashboardState> {
  pim: Pim;

  constructor(props: DashboardProps) {
    super(props);
    const { keycloak, agency } = props;
    this.pim =
      props.pim ||
      new Pim({
        url: process.env.REACT_APP_PIM_BASEURL as string,
        pgRestUrl: process.env.REACT_APP_PGREST_PROXY,
        keycloak,
        agency,
      });
    this.state = { context: null, theme: null };
  }
  // update the dashboard theme

  onDashboardThemeChange = (updatedTheme: string) => {
    this.setState(prevState => ({
      context: prevState.context,
      theme: updatedTheme,
    }));
  };

  getDataFromPim = async () => {
    const {
      currentUser: externalUser,
      patient,
      patientNumber = window.location.pathname.substring(1).split('/')[0],
    } = this.props;

    const params = new URLSearchParams(window.location.search);
    const siteId = params.get('siteId');

    const [currentUser, patientResponse] = await Promise.all([
      // if provided a user via props, just pass it along
      !externalUser ? this.pim.getUserInfo() : Promise.resolve(externalUser),
      !patient && patientNumber
        ? this.pim.getPatientHeader(patientNumber)
        : Promise.resolve(patient),
    ]);

    // TODO find better way than throw error
    if (currentUser instanceof Response) {
      throw new Error(currentUser.statusText);
    }
    let patientState;
    if (patientNumber) {
      patientState =
        patientResponse instanceof Response
          ? { ...setPatientError(patientResponse, patientNumber) }
          : { patient: setPatient(patientResponse) };
    } else {
      patientState = { patient: undefined };
    }

    const dashboardType: DashboardType = patientNumber
      ? patientDashboard
      : clinicalDashboard;

    this.setState({
      context: {
        currentUser,
        siteId,
        keycloak: this.props.keycloak,
        locale: this.props.locale || DEFAULT_LOCALE,
        pim: this.pim,
        onDashboardThemeChange: this.onDashboardThemeChange,
        dashboardType,
        config:
          this.props.config || process.env.REACT_APP_WIDGET_CONFIG || texts,
        ...patientState,
      },
      theme: localStorage.getItem(THEME),
    });
  };

  componentDidMount() {
    // form widget
    localStorage.removeItem(FORM_NAME);
    // overall theme of dashboard
    // dashboard sets the defaultTheme if none
    const theme = localStorage.getItem(THEME);
    !theme && localStorage.setItem(THEME, defaultTheme);
    this.getDataFromPim();
  }

  render() {
    // theme is saved in state and updated when changed
    const { context, theme } = this.state;
    const { embedded, locale = DEFAULT_LOCALE } = this.props;
    return (
      <Providers locale={locale} key={locale} theme={theme}>
        <BrowserRouter>
          <Content context={context} embedded={embedded} />
        </BrowserRouter>
      </Providers>
    );
  }
}

export default Dashboard;
